
    import { defineComponent, ref, watch, onMounted } from 'vue';
    import { ElConfigProvider } from 'element-plus';
    import ptBr from 'element-plus/lib/locale/lang/pt-br';
    import XLSX from 'xlsx-js-style';
    import moment from 'moment';
    import useEmitter from '@/composables/Emmiter'
    import AuthService from "@/services/AuthService";
    import Swal from "sweetalert2/dist/sweetalert2.min.js";
    import ApiService from '@/services/ApiService';
	import { getHistoricoChecklistSetor } from "@/services/HistoricoChecklistService";
    import { getOficinaDigitalConsultores } from "@/services/OficinaDigital";
    import { useStore } from 'vuex';
    import { Actions } from "@/store/enums/StoreEnums";
    import FilterBase from '@/layout/header/partials/filters/FilterBase.vue';
    import { nameTitleUserSingular } from "@/core/config/DistribuidorNameUser"

    export default defineComponent({
        name: "filtro-historico",
        components: {
            FilterBase,
            ElConfigProvider,
        },

        setup(props, { emit }) {
            const store = useStore();
            const distribuidor = store.getters.layoutConfig("distribuidor.distribuidor");
            const dataInicialPeriodo = moment().startOf("month").toISOString();
            const dataFInalPeriodo = moment().endOf("day").toISOString();
            const periodoMesAtual = [dataInicialPeriodo, dataFInalPeriodo];
            const cacheInicialData = window.sessionStorage.getItem("filtroData") ?? ""; 
            const valorInicialData = cacheInicialData ? cacheInicialData.split(",") : periodoMesAtual;

            let valorInicialCodConcessionaria;
            if(window.localStorage.getItem("id_usuario")){
                valorInicialCodConcessionaria = window.localStorage.getItem("filtroConcessionaria") ?? "";
            } else {
                valorInicialCodConcessionaria = window.sessionStorage.getItem("filtroConcessionaria") ?? "";
            }
            const codConcessionariaSelected:any = ref(valorInicialCodConcessionaria ?? "");
          
            const datePicker = ref(valorInicialData);

            const userInfo = AuthService.getUsuarioAuth();
            const token = userInfo.token;
            const emitter = useEmitter();

            const error = ref(false);

            const activeModal = ref(false);
            const disabledClick = ref(false);
            const closeModal = ref(false);
            
            const lastEmitIsNull = ref(false);
            const modalFiltro:any = ref();
            const showClose = ref(false)

            const optionsConcessionaria:any= ref([]);
            const setorSelected:any= ref([]);
            const optionsSetor:any= ref([]);
            const placa:any=ref("");
            const chassi:any=ref("");
            const numeroOS = ref("")
            const multiplesPlaca:any= ref([]);
            const cliente:any=ref("");
            const filter = ref(['periodo']);
            const label = ref("Faça filtros por concessionária e data");
            const showGoToBack = ref(false);
            const optionsConsultor:any= ref([]);
            const consultorSelected:any= ref("");

            const loading:any= ref({
                concessionaria: false,
                filtrosConsultor: false,
                filtrosSetor: false,
            });

            watch(datePicker, () => {
                if(datePicker.value == null){
                    datePicker.value = periodoMesAtual;
                    return;
                };
            });

            store.watch(() => store.getters.concessionariasList, () => {
                trataConcessionarias();
            }, { deep: true });

            onMounted(()=>{
                if(store.getters.concessionariasList.length > 0){
                    trataConcessionarias();
                }

                if(codConcessionariaSelected.value){
                    getSetoresOptions();
                    getConsultorOptions();
                }
            });

            function checkFilterPlaca() {
                if(filter.value.length == 1 && filter.value.includes("periodo")){
                    filter.value = filter.value.filter(filtro => filtro != "periodo")
                }
            }

            async function getConsultorOptions() {
                loading.value.filtrosConsultor = true;
                consultorSelected.value = "";

                const response = await getOficinaDigitalConsultores(codConcessionariaSelected.value);
                const consultores = response;

                optionsConsultor.value = consultores.map(consultor => {
                    return {
                        text: consultor.nome,
                        value: consultor.codConsultor,
                    }
                });

                loading.value.filtrosConsultor = false;
            };

            async function getSetoresOptions() {
                loading.value.filtrosSetor = true;
                setorSelected.value = [];

                const response = await getHistoricoChecklistSetor(codConcessionariaSelected.value);
                const setores = response;

                optionsSetor.value = setores.map(setor => {
                    return {
                        text: setor.setor,
                        value: setor.status,
                    }
                });

                loading.value.filtrosSetor = false;
            };

            function truncateWord(word: String, maxLength: number){
               let  myTruncateWord = "";
                if(word.length > maxLength){
                    myTruncateWord = word.substring(0, maxLength);
                    myTruncateWord += "...";

                    return myTruncateWord;
                }

                return word;
            }

            function addPlaca() { 
                if(placa.value.length == 8){
                    const placaDuplicated = multiplesPlaca.value.find(placaAux => placaAux == placa.value);

                    if(!placaDuplicated){
                        multiplesPlaca.value.push(placa.value);
                    }

                    placa.value = ""
                }
            };

            function removePlaca(index) { 
                multiplesPlaca.value.splice(index, 1);
            };

            async function trataConcessionarias() {
                const concessionarias = store.getters.concessionariasList;

                optionsConcessionaria.value = concessionarias.map(concessionaria => {
                    return {
                        text: concessionaria.nomeFantasia,
                        value: concessionaria.codConcessionaria,
                        disabled: !concessionaria.temChecklist,
                    }
                }).sort((concessionariaA, concessionariaB) => {
                    if (!concessionariaA.disabled && concessionariaB.disabled) {
                    return -1;
                    }
                    if (!concessionariaB.disabled && concessionariaA.disabled) {
                    return 1;
                    }

                    if (concessionariaA.disabled && !concessionariaA.disabled) {
                    if (concessionariaA.text < concessionariaB.text){
                        return -1;
                    }
                    if (concessionariaA.text > concessionariaB.text){
                        return 1;
                    }
                    return 0;
                    }

                    return 0;
                });

                const cacheConcessionariaSelected = store.getters.concessionariaSelectedInfo;
                if(cacheConcessionariaSelected.codConcessionaria){
                    if(cacheConcessionariaSelected.temChecklist){
                        codConcessionariaSelected.value = cacheConcessionariaSelected.codConcessionaria;
                        emiteMudanca(datePicker.value);
                    } else {
                        showGoToBack.value = true;
                        label.value = "Selecione uma concessionária com checklist";
                        codConcessionariaSelected.value = optionsConcessionaria.value[0].disabled ? null : optionsConcessionaria.value[0].value;
                        activeModal.value = !activeModal.value;
                        showClose.value = false;
                    }
                } else {
                    codConcessionariaSelected.value = optionsConcessionaria.value[0].disabled ? null : optionsConcessionaria.value[0].value;
                    activeModal.value = !activeModal.value;
                    showClose.value = false;
                }
            };

            function desabilitaDatasAnteriores (data){
                const dataAnterior = data.getTime() > new Date("2016-01-01T00:00:00") === data.getTime() > Date.now()
                return dataAnterior
            };
            
            function verificaIntervalo(data){
                var diff = moment(data[1]).diff(moment(data[0]), "months");
                if(diff <= 24) {
                    emiteMudanca(datePicker.value);
                } else {
                   Swal.fire({
                        title: "Período inválido!",
                        html: `<h4>Não é possível filtrar um período superior a 24 meses (2 anos)!</h4>`,
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Ok!",
                        customClass: {
                        confirmButton: "btn btn-sm btn-outline background_btn_2_opct border_btn_1 cor_btn_1",
                        },
                    });
                }
            };

            function retornaFiltroData(dataInicial, dataFinal){
                dataInicial = moment(dataInicial).startOf('day').format("YYYY-MM-DD HH:mm:ss");
                dataFinal = moment(dataFinal).endOf('day').format("YYYY-MM-DD HH:mm:ss");

                const filters = `${dataInicial}/${dataFinal}`;
                return filters;
            };

            let blob = ref();
            emitter.on("geraXLSX", () => {
                // gerarXLSX();
            });

            emitter.on("exportXLSX", () => {
                exportarXLSX();
            });

            function gerarXLSX() {
                // let wb: XLSX.WorkBook = XLSX.utils.book_new();
                // wb.Props = {
                //     Title: "SheetJS Tutorial",
                //     Subject: "Test",
                //     Author: "Red Stapler",
                //     CreatedDate: new Date(2017,12,19)
                // };
                // wb.SheetNames.push("MO");

                // const ws = XLSX.utils.json_to_sheet(itensReprovadosToXLSX.value.length == 0 ? [
                //     {
                //     "Item": "",
                //     "Cliente": "",
                //     "Modelo": "",
                //     "E-mail": "",
                //     "Telefone": "",
                //     "Consultor": "",
                //     "Mecânico": "",
                //     "Data Reprovação": "",
                //     "Motivo Reprovação": "",
                //     "Pré-Agendamento Contato": "",
                //     "Aceita Negociação": ""
                //     }
                // ] : itensReprovadosToXLSX.value);
                // const wscols = [
                //     {wch:45},
                //     {wch:30},
                //     {wch:30},
                //     {wch:30},
                //     {wch:30},
                //     {wch:30},
                //     {wch:30},
                //     {wch:30},
                //     {wch:30},
                //     {wch:30},
                //     {wch:30},
                // ]
                // ws['!cols'] = wscols;

                // ws["A1"].s = {
                //     fill: {
                //     fgColor: { rgb: "FFD6D8DB" },
                //     },
                // }
                // ws["B1"].s = {
                //     fill: {
                //     fgColor: { rgb: "FFD6D8DB" },
                //     },
                // }
                // ws["C1"].s = {
                //     fill: {
                //     fgColor: { rgb: "FFD6D8DB" },
                //     },
                // }
                // ws["D1"].s = {
                //     fill: {
                //     fgColor: { rgb: "FFD6D8DB" },
                //     },
                // }
                // ws["E1"].s = {
                //     fill: {
                //     fgColor: { rgb: "FFD6D8DB" },
                //     },
                // }
                // ws["F1"].s = {
                //     fill: {
                //     fgColor: { rgb: "FFD6D8DB" },
                //     },
                // }
                // ws["G1"].s = {
                //     fill: {
                //     fgColor: { rgb: "FFD6D8DB" },
                //     },
                // }
                // ws["H1"].s = {
                //     fill: {
                //     fgColor: { rgb: "FFD6D8DB" },
                //     },
                // }
                // ws["I1"].s = {
                //     fill: {
                //     fgColor: { rgb: "FFD6D8DB" },
                //     },
                // }
                // ws["J1"].s = {
                //     fill: {
                //     fgColor: { rgb: "FFD6D8DB" },
                //     },
                // }
                // ws["K1"].s = {
                //     fill: {
                //     fgColor: { rgb: "FFD6D8DB" },
                //     },
                // }

                // wb.Sheets["MO"] = ws;

                // function s2ab(s) { 
                //     var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
                //     var view = new Uint8Array(buf);  //create uint8array as viewer
                //     for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
                //     return buf;    
                // }

                // var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});

                // blob.value = new Blob([s2ab(wbout)], {type: 'text/plain;charset=UTF-8'});
            };

            function exportarXLSX(){
                const link = document.createElement('a');
                if (link.download !== undefined) {
                    const url = URL.createObjectURL(blob.value);
                    link.setAttribute('href', url);
                    link.setAttribute('download', "export_itens_reprovados.xlsx");
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);  
                };
            };

            async function emiteMudanca(valorData){
                label.value = "Faça filtros por concessionária, data, setor, placa e nome do cliente";
                showGoToBack.value = false;
                ///// valida o token no inicio da ação evitando que se o token esta expirado fazer varias autenticacoes iniciais
                await ApiService.valideTokenTime();
                /////

                const dataTratada:any = valorData ? retornaFiltroData(valorData[0], valorData[1]) : retornaFiltroData(periodoMesAtual[0], periodoMesAtual[1]);
                const arrayDataTratada = dataTratada.split("/");

                if(window.localStorage.getItem("id_usuario")){
                    window.localStorage.setItem("filtroConcessionaria", codConcessionariaSelected.value);
                } else {
                    window.sessionStorage.setItem("filtroConcessionaria", codConcessionariaSelected.value);
                }

                let auxPlaca = [...multiplesPlaca.value, placa.value].filter(value => value)

                const postDataFiltro = {
                    codConsultor: filter.value.includes('consultor') ? consultorSelected.value : "",
                    placa: filter.value.includes('placa') ? auxPlaca : [],
                    chassi: filter.value.includes('chassi') ? chassi.value : "",
                    cliente: filter.value.includes('cliente') ? cliente.value : "",
                    setor: filter.value.includes('setor') ? setorSelected.value : [],
                    data: filter.value.includes('periodo') ? `${moment(arrayDataTratada[0], "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")} - ${moment(arrayDataTratada[1], "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}` : "",
                    numeroOS: filter.value.includes('numeroOS') ? [numeroOS.value]: []
                };

                const dataSelecionada = filter.value.includes('periodo') ? {
                    dataInicial: moment(arrayDataTratada[0], "YYYY-MM-DD HH:mm:ss"),
                    dataFinal: moment(arrayDataTratada[1], "YYYY-MM-DD HH:mm:ss")
                } : null;
                   
                if(valorData == null){
                    if(lastEmitIsNull.value){
                        return;
                    };
                    lastEmitIsNull.value = true;
                    store.dispatch(Actions.SET_CONCESSIONARIA_SELECTED, codConcessionariaSelected.value);
                    
                    emitter.emit("filtrar-historico-checklist", { postData: postDataFiltro, dataSelecionada, codConcessionaria: codConcessionariaSelected.value});
                    closeModal.value = !closeModal.value;
                };

                lastEmitIsNull.value = false;
                store.dispatch(Actions.SET_CONCESSIONARIA_SELECTED, codConcessionariaSelected.value);
                
                emitter.emit("filtrar-historico-checklist", { postData: postDataFiltro, dataSelecionada, codConcessionaria: codConcessionariaSelected.value});
                closeModal.value = !closeModal.value;
            };

            return {
                activeModal,
                closeModal,
                showClose,
                modalFiltro,
                loading, 
                ptBr, 
                datePicker, 
                emitter, 
                emiteMudanca, 
                periodoMesAtual, 
                lastEmitIsNull, 
                desabilitaDatasAnteriores,
                optionsConcessionaria,
                codConcessionariaSelected,
                verificaIntervalo,
                optionsSetor,
                setorSelected,
                filter,
                getSetoresOptions,
                placa,
                multiplesPlaca,
                cliente,
                error,
                label,
                showGoToBack,
                disabledClick,
                optionsConsultor,
                consultorSelected,
                chassi,
                getConsultorOptions,
                checkFilterPlaca,
                addPlaca,
                removePlaca,
                nameTitleUserSingular,
                distribuidor,
                numeroOS
            };
        }
    });
